import React from 'react';
import {
  List,
  Edit,
  Datagrid,
  DateField,
  TextField,
  EditButton,
  TextInput,
  SelectInput,
  ShowButton,
  TabbedForm,
  FormTab,
  Filter,
  TabbedShowLayout,
  Show,
  Tab,
  ShowGuesser,
  SearchInput,
  Resource,
  Create,
  NumberInput,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  TopToolbar,
  ExportButton,
  CreateButton,
  DeleteButton,
  downloadCSV,
  ReferenceManyField,
} from 'react-admin';
import UserReferenceField from 'shared-fields/UserReferenceField';
import UserReferenceInput from 'shared-fields/UserReferenceInput';
import { generateSelectOptions } from 'utils/filters-utils';
import { ChannelState } from 'constants/channel-state.enum';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useHasRole } from 'hooks/useHasRole';
import Role from 'constants/role';
import { rolesMatch } from 'utils/user';
import RangeField from 'shared-fields/RangeField';
import jsonExport from 'jsonexport/dist';
import { ActivityLoggerListContent } from 'views/activity-logger';
import { StateField } from './StateField';
import { Title } from './Title';
import { Health } from './Health';
import { SourceField } from './SourceField';
import { DescriptionField } from './DescriptionField';
import { AdSenseField } from './AdsenseField';

function ChannelFilter(props) {
  return (
    <Filter {...props}>
      <SearchInput
        source="searchQuery"
        alwaysOn
        sx={{
          '& .MuiFilledInput-root': {
            minWidth: 110,
            width: 150,
          },
        }}
      />
      <UserReferenceInput alwaysOn />
      <SelectInput
        source="state"
        choices={generateSelectOptions(ChannelState, true)}
        alwaysOn
      />
      <SelectInput
        source="adSenseId"
        choices={adSenseOptions}
        alwaysOn
      />
      <DateInput
        source="createdAtFrom"
        locales="fa-IR"
        label="from"
        alwaysOn
        sx={{
          '& .MuiFilledInput-root': {
            minWidth: 110,
          },
        }}
      />
      <DateInput
        source="createdAtEnd"
        label="to"
        alwaysOn
        sx={{
          '& .MuiFilledInput-root': {
            minWidth: 110,
          },
        }}
      />
    </Filter>
  );
}

const exporter = (channels) => {
  const channelsForExport = channels.map((ch) => ({
    ...ch,
    incomeSharing: undefined,
  }));
  jsonExport(channelsForExport, (err, csv) => {
    downloadCSV(csv, 'channels');
  });
};

function BulkActionButtons() {
  const hasRole = useHasRole();
  return hasRole(Role.ROLE_ADMIN_CHANNEL_DELETE) && <DeleteButton />;
}
export function ChannelListContent(props) {
  const hasRole = useHasRole();

  return (
    <Datagrid {...props} bulkActionButtons={BulkActionButtons}>
      <TextField source="id" />
      <StateField />
      <Title />
      <TextField source="ownerTel" label="Tel" />
      <TextField source="ownerName" label="Owner" />
      <AdSenseField />
      <RangeField source="averageIncome" label="Avg Inc" max={15000} palette="green" humanize />
      <RangeField source="subscriberCount" label="Subs" max={1000} palette="green" humanize />
      <TextField source="videoCount" label="Videos" />
      <RangeField source="viewCount" label="Views" max={1000000} humanize />
      <SourceField />
      <Health />
      <UserReferenceField />
      <DateField source="createdAt" label="Created" showTime locales="fa-IR" />
      <DateField source="syncedAt" label="Synced" showTime locales="fa-IR" />
      {
        hasRole(Role.ROLE_ADMIN_CHANNEL_EDIT) && (
          <EditButton />
        )
      }

      <ShowButton />
    </Datagrid>
  );
}
export function ChannelList() {
  const hasRole = useHasRole();
  return (
    <List
      sort={{ field: 'id', order: 'DESC' }}
      filters={<ChannelFilter />}
      filterDefaultValues={{
        state: `!${ChannelState.REJECTED}`,
      }}
      exporter={exporter}
      perPage={25}
      actions={(
        <TopToolbar>
          {hasRole(Role.ROLE_SUPER_ADMIN) && <ExportButton />}
          {hasRole(Role.ROLE_ADMIN_CHANNEL_CREATE) && <CreateButton />}
          {/* <FilterButton filters={<ChannelFilter />} /> */}
        </TopToolbar>
      )}
    >
      <ChannelListContent />
    </List>
  );
}

export function ChannelEdit(props) {
  const hasRole = useHasRole();

  return (
    <Edit mutationMode="optimistic" {...props}>
      <TabbedForm>
        <FormTab label="channel">
          <TextInput source="id" disabled />
          <TextInput source="title" disabled />
          <TextInput source="ownerName" />
          <TextInput source="ownerTel" />
          <TextInput source="ownerEmail" />
          <TextInput source="url" />
          <TextInput source="leadReferrer" />
          <NumberInput source="subscriberCount" />
          <NumberInput source="videoCount" />
          <NumberInput source="viewCount" />
          <SelectInput source="state" choices={channelStateChoices} />
          <TextInput source="description" multiline fullWidth />
          <UserReferenceInput />
          <SelectInput
            source="adSenseId"
            choices={adSenseOptions}
            alwaysOn
          />
          <DateInput source="remindAt" />
        </FormTab>
        {
          hasRole(Role.ROLE_ADMIN_FINANCIAL) && (
            <FormTab label="Income Sharing">
              <ArrayInput source="incomeSharing">
                <SimpleFormIterator inline>
                  <UserReferenceInput />
                  <NumberInput source="percentage" helperText={false} />
                  <TextInput source="description" />
                  <DateInput source="expireAt" />
                  <NumberInput source="minimum" helperText={false} placeholder="Default: 11" />
                </SimpleFormIterator>
              </ArrayInput>
            </FormTab>
          )
        }
        {
          hasRole(Role.ROLE_SUPER_ADMIN) && (
            <FormTab label="History">
              <ActivityTabContent />
            </FormTab>
          )
        }
      </TabbedForm>
    </Edit>
  );
}
export function ChannelCreate(props) {
  return (
    <Create mutationMode="optimistic" {...props}>
      <TabbedForm>
        <FormTab label="url">
          <TextInput source="id" disabled />
          <TextInput source="ownerName" />
          <TextInput source="ownerTel" />
          <TextInput source="ownerEmail" />
          <TextInput source="url" />
          <TextInput source="leadSource" />
          <NumberInput source="subscriberCount" />
          <NumberInput source="videoCount" />
          <NumberInput source="viewCount" />
          <SelectInput source="state" choices={channelStateChoices} />
          <UserReferenceInput />
          <SelectInput
            source="adSenseId"
            choices={adSenseOptions}
            alwaysOn
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
}

function ActivityTabContent(props) {
  return (
    <ReferenceManyField
      {...props}
      perPage={100}
      reference="activity-logger"
      target="recordId"
      addLabel={false}
      filter={{ recordModel: 'Channel' }}
    >
      <ActivityLoggerListContent />
    </ReferenceManyField>
  );
}
export function ChannelShow(props) {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="general">
          <ShowGuesser {...props} />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
}

const channelStateChoices = generateSelectOptions(ChannelState);

export const renderChannelResource = (roles) => (
  <Resource
    name="channel"
    show={ChannelShow}
    list={ChannelList}
    edit={rolesMatch({ roles, role: Role.ROLE_ADMIN_CHANNEL_EDIT }) && ChannelEdit}
    create={rolesMatch({ roles, role: Role.ROLE_ADMIN_CHANNEL_CREATE }) && ChannelCreate}
    icon={SubscriptionsIcon}
  />
);

const adSenseOptions = [
  {
    id: '3041134335536247',
    name: '3041134335536247',
  },
  {
    id: '3280661369437693',
    name: '3280661369437693',
  },
  {
    id: '4535316852950227',
    name: '4535316852950227',
  },
  {
    id: '9738256357570364',
    name: '9738256357570364',
  },
  {
    id: '7717229326906583',
    name: '7717229326906583',
  },
  {
    id: '6397070256551068',
    name: '6397070256551068',
  },
];
