import React from 'react';
import { objectWithFallback } from 'utils/object-utils';
import GeneralChipField from 'shared-fields/GeneralChipField';
import { useRecordContext } from 'react-admin';
import { ChannelState } from 'constants/channel-state.enum';

import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import PolicyIcon from '@mui/icons-material/Policy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HelpIcon from '@mui/icons-material/Help';
import EventIcon from '@mui/icons-material/Event';

const ICON_MAP = objectWithFallback(
  {
    [ChannelState.MONETIZED]: <CheckCircleIcon />,
    [ChannelState.REJECTED]: <ThumbDownIcon />,
    [ChannelState.UNDER_REVIEW]: <PolicyIcon />,
    [ChannelState.ACCEPTED]: <ThumbUpIcon />,
    [ChannelState.UNKNOWN]: <HelpIcon />,
    [ChannelState.FOR_FUTURE]: <EventIcon />,
  },
  null,
);
const COLOR_MAP = objectWithFallback(
  {
    [ChannelState.MONETIZED]: 'success',
    [ChannelState.UNDER_REVIEW]: 'warning',
    [ChannelState.ACCEPTED]: 'success',
    [ChannelState.REJECTED]: 'error',
    [ChannelState.FOR_FUTURE]: 'info',
  },
  'default',
);
const VARIANT_MAP = objectWithFallback(
  {
    [ChannelState.MONETIZED]: 'default',
  },
  'outlined',
);

export function StateField(props) {
  const channel = useRecordContext() || {};

  return (
    <GeneralChipField
      iconMap={ICON_MAP}
      colorMap={COLOR_MAP}
      variantMap={VARIANT_MAP}
      {...props}
      source="state"
    />
  );
}

StateField.defaultProps = {
  label: 'State',
};
