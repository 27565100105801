import React from 'react';
import { useRecordContext } from 'react-admin';
import { Chip } from '@mui/material';
import { objectWithFallback } from 'utils/object-utils';

function GeneralChipField({
  source,
  iconMap,
  colorMap,
  color,
  variantMap,
  compact,
}) {
  const record = useRecordContext() || {};
  const value = record[source];
  if (value == null) {
    return '-';
  }
  return (
    <Chip
      icon={iconMap[value]}
      label={compact ? (value || '').split('_').slice(-1)[0] : value}
      color={color || colorMap[value]}
      variant={variantMap[value]}
      size="small"
    />
  );
}

GeneralChipField.defaultProps = {
  compact: false,
  variantMap: objectWithFallback({}, 'outlined'),
  colorMap: objectWithFallback({}, 'default'),
};
export default GeneralChipField;
